import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "preact/hooks";
import { CtaBoxTablet } from "../../../components/cta-box";
import { FooterTablet } from "../../../components/footer";
import { TabletHeader } from "../../../components/header";
import { HeroBannerTablet } from "../../../components/hero-banner";
import { PainPointTablet } from "../../../components/pain-point";
import { PartnerTablet } from "../../../components/partner";
import { PayroadProcessTablet } from "../../../components/payroad-process";
import { PayroadServiceTablet } from "../../../components/payroad-service";
import { TargetMessageTablet } from "../../../components/target-message";
import { TargetUserTablet } from "../../../components/target-user";
import { UserCaseTablet } from "../../../components/user-case";
import { FloatingButtonTablet } from "src/components/floating-button";

const TabletScreen = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<boolean>(false);
  const [scrollMid, setScrollMid] = useState<boolean>(false);
  const [scrollBot, setScrollBot] = useState<boolean>(false);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition + window.innerHeight > 1660) {
      setScrollTop(true);
    }
    if (scrollPosition + window.innerHeight > 8150) {
      setScrollMid(true);
    }
    if (scrollPosition + window.innerHeight > 12770) {
      setScrollBot(true);
    }
  }, [scrollPosition]);

  useEffect(() => {
    track("scroll_top_landing");
  }, [scrollTop]);
  useEffect(() => {
    track("scroll_middle_landing");
  }, [scrollMid]);
  useEffect(() => {
    track("scroll_bottom_landing");
  }, [scrollBot]);

  return (
    <>
      <TabletHeader />
      <div className="flex flex-col items-center pt-[72px]">
        <HeroBannerTablet />
        <TargetMessageTablet />
        <PainPointTablet />
        <PayroadServiceTablet />
        <TargetUserTablet />
        <PayroadProcessTablet />
        <UserCaseTablet />
        <PartnerTablet />
        <CtaBoxTablet />
        <FooterTablet />
        <FloatingButtonTablet />
      </div>
    </>
  );
};

export default TabletScreen;
