import { track } from "@amplitude/analytics-browser";
import { PAYROAD_SIGNUP_LINK } from "src/statics/links";

const gtagReportConversion = () => {
  window.gtag("event", "conversion", {
    send_to: "AW-11145749137/BD56CO-WqaAYEJHF2sIp",
  });

  window.fbq("track", "InitiateSignup", {
    content_name: "payroad",
  });
};

const _onClickCta = () => {
  gtagReportConversion();
  track("click_cta_floating");
  window.location.href = PAYROAD_SIGNUP_LINK;
};

export const FloatingButtonDesktop = () => {
  return (
    <button
      className="fixed bottom-[32px] h-[56px] bg-prblue w-full max-w-[1200px] body2-sb text-white flex items-center justify-center rounded-[10px] hover:bg-prblue-40 active:bg-prblue-60 shadow-xl"
      onClick={_onClickCta}
    >
      지금 후불 결제 이용하기
    </button>
  );
};

export const FloatingButtonTablet = () => {
  return (
    <button
      className="fixed bottom-[32px] w-[calc(100%-80px)] h-[56px] bg-prblue body2-sb text-white flex items-center justify-center rounded-[10px] hover:bg-prblue-40 active:bg-prblue-60 shadow-xl"
      onClick={_onClickCta}
    >
      지금 후불 결제 이용하기
    </button>
  );
};

export const FloatingButtonMobile = () => {
  return (
    <button
      className="fixed bottom-[32px] w-[calc(100%-40px)] h-[56px] bg-prblue body2-sb text-white flex items-center justify-center rounded-[10px] hover:bg-prblue-40 active:bg-prblue-60 shadow-xl"
      onClick={_onClickCta}
    >
      지금 후불 결제 이용하기
    </button>
  );
};
