import { FaqMobile } from "src/components/faq";
import { FooterMobile } from "src/components/footer";
import { MobileHeader } from "src/components/header";

const MobileScreen = () => {
  return (
    <>
      <MobileHeader />
      <div className="flex flex-col items-center pt-[72px]">
        <FaqMobile />
        <FooterMobile />
      </div>
    </>
  );
};

export default MobileScreen;
