import arrowDown from "../../assets/images/arrow-down.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import form from "../../assets/images/form.png";
import handshake from "../../assets/images/handshake.png";
import payroad from "../../assets/images/payroad-check.png";
import signup from "../../assets/images/signup.png";
import store from "../../assets/images/store-card.png";

export const PayroadProcessDesktop = () => {
  return (
    <div className=" bg-prblue-5 w-full h-[548px] flex flex-col items-center py-[100px]">
      <div className="h1-eb text-prblue mb-[74px]">
        당일 신청, 당일 승인으로 24시간 내에 거래가 완료됩니다.
      </div>
      <div className="flex gap-x-[24px]">
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[160px] h-[160px] rounded-full mb-[24px]">
            <img src={signup} className="w-[78px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub2-sb text-white bg-prblue rounded-[50px] px-[16px]">
            회원가입
          </div>
        </div>
        <img src={arrowRight} className="mb-[65px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[160px] h-[160px] rounded-full mb-[24px]">
            <img src={store} className="w-[90px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub2-sb text-white bg-prblue rounded-[50px] px-[16px]">
            스토어 연동
          </div>
        </div>
        <img src={arrowRight} className="mb-[65px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[160px] h-[160px] rounded-full mb-[24px]">
            <img src={form} className="w-[81px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub2-sb text-white bg-prblue rounded-[50px] px-[16px]">
            후불 결제 신청
          </div>
        </div>
        <img src={arrowRight} className="mb-[65px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[160px] h-[160px] rounded-full mb-[24px]">
            <img src={handshake} className="w-[100px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub2-sb text-white bg-prblue rounded-[50px] px-[16px]">
            거래 기업 승인
          </div>
        </div>
        <img src={arrowRight} className="mb-[65px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[160px] h-[160px] rounded-full mb-[24px]">
            <img src={payroad} className="w-[94px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub2-sb text-white bg-prblue rounded-[50px] px-[16px]">
            후불 결제 이용
          </div>
        </div>
      </div>
    </div>
  );
};

export const PayroadProcessTablet = () => {
  return (
    <div className=" bg-prblue-5 w-full h-[504px] flex flex-col items-center py-[100px]">
      <div className="h1-eb text-prblue mb-[74px] text-center">
        당일 신청, 당일 승인으로
        <br />
        24시간 내에 거래가 완료됩니다.
      </div>
      <div className="flex gap-x-[14.27px]">
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[95px] h-[95px] rounded-full mb-[12px]">
            <img src={signup} className="w-[46px]" alt="" />
          </div>
          <div className="h-[24.38px] flex items-center justify-center body3-sb text-white bg-prblue rounded-[50px] px-[9.5px]">
            회원가입
          </div>
        </div>
        <img src={arrowRight} className="w-[28.5px] mb-[39.5px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[95px] h-[95px] rounded-full mb-[12px]">
            <img src={store} className="w-[46px]" alt="" />
          </div>
          <div className="h-[24.38px] flex items-center justify-center body3-sb text-white bg-prblue rounded-[50px] px-[9.5px]">
            스토어 연동
          </div>
        </div>
        <img src={arrowRight} className="w-[28.5px] mb-[39.5px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[95px] h-[95px] rounded-full mb-[12px]">
            <img src={form} className="w-[48px]" alt="" />
          </div>
          <div className="h-[24.38px] flex items-center justify-center body3-sb text-white bg-prblue rounded-[50px] px-[9.5px]">
            후불 결제 신청
          </div>
        </div>
        <img src={arrowRight} className="w-[28.5px] mb-[39.5px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[95px] h-[95px] rounded-full mb-[12px]">
            <img src={handshake} className="w-[59px]" alt="" />
          </div>
          <div className="h-[24.38px] flex items-center justify-center body3-sb text-white bg-prblue rounded-[50px] px-[9.5px]">
            거래 기업 승인
          </div>
        </div>
        <img src={arrowRight} className="w-[28.5px] mb-[39.5px]" alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[95px] h-[95px] rounded-full mb-[12px]">
            <img src={payroad} className="w-[56px]" alt="" />
          </div>
          <div className="h-[24.38px] flex items-center justify-center body3-sb text-white bg-prblue rounded-[50px] px-[9.5px]">
            후불 결제 이용
          </div>
        </div>
      </div>
    </div>
  );
};

export const PayroadProcessMobile = () => {
  return (
    <div className=" bg-prblue-5 w-full h-[1285px] flex flex-col items-center py-[60px]">
      <div className="h3-eb text-prblue mb-[48px] text-center">
        당일 신청, 당일 승인으로
        <br />
        24시간 내에 거래가 완료됩니다.
      </div>
      <div className="flex flex-col gap-y-[24px] items-center">
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[100px] h-[100px] rounded-full mb-[12px]">
            <img src={signup} className="w-[46px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub4-sb text-white bg-prblue rounded-[50px] px-[16px]">
            회원가입
          </div>
        </div>
        <img src={arrowDown} alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[100px] h-[100px] rounded-full mb-[12px]">
            <img src={store} className="w-[46px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub4-sb text-white bg-prblue rounded-[50px] px-[16px]">
            스토어 연동
          </div>
        </div>
        <img src={arrowDown} alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[100px] h-[100px] rounded-full mb-[12px]">
            <img src={form} className="w-[48px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub4-sb text-white bg-prblue rounded-[50px] px-[16px]">
            후불 결제 신청
          </div>
        </div>
        <img src={arrowDown} alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[100px] h-[100px] rounded-full mb-[12px]">
            <img src={handshake} className="w-[59px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub4-sb text-white bg-prblue rounded-[50px] px-[16px]">
            거래 기업 승인
          </div>
        </div>
        <img src={arrowDown} alt="" />
        <div className="flex flex-col items-center">
          <div className="flex items-center justify-center bg-white w-[100px] h-[100px] rounded-full mb-[12px]">
            <img src={payroad} className="w-[56px]" alt="" />
          </div>
          <div className="h-[41px] flex items-center justify-center sub4-sb text-white bg-prblue rounded-[50px] px-[16px]">
            후불 결제 이용
          </div>
        </div>
      </div>
    </div>
  );
};
