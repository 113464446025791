import { track } from "@amplitude/analytics-browser";
import { useState } from "preact/hooks";
import hamburger from "../../assets/images/hamburger.svg";
import payroadLogo from "../../assets/images/payroad-logo.svg";
import xMark from "../../assets/images/x-mark.svg";
import {
  PAYROAD_FAQ_LINK,
  PAYROAD_LOGIN_LINK,
  PAYROAD_MAIN_LINK,
  PAYROAD_SIGNUP_LINK,
} from "../../statics/links";

const _onClickLogo = () => {
  window.location.href = PAYROAD_MAIN_LINK;
};
const _onClickFaq = () => {
  window.location.href = PAYROAD_FAQ_LINK;
};
const _onClickLogin = () => {
  track("click_login_gnb");
  window.location.href = PAYROAD_LOGIN_LINK;
};
const _onClickSignup = () => {
  track("click_signup_gnb");
  window.location.href = PAYROAD_SIGNUP_LINK;
};

export const DesktopHeader = () => {
  return (
    <div
      className="fixed bg-white flex w-full h-[72px] justify-between items-center px-[40px] border-b border-prgray-20"
      style={{ zIndex: 9999 }}
    >
      <button onClick={_onClickLogo}>
        <img src={payroadLogo} className="w-[92px] h-[40px]" alt="" />
      </button>
      <div className="flex gap-x-[8px] sub4-sb">
        <button
          className="w-[78px] h-[40px] rounded-[46px] hover:bg-prgray-20 active:bg-prgray-40"
          onClick={_onClickFaq}
        >
          고객지원
        </button>
        <button
          className="w-[78px] h-[40px] rounded-[46px] hover:bg-prgray-20 active:bg-prgray-40"
          onClick={_onClickLogin}
        >
          로그인
        </button>
        <button
          className="w-[78px] h-[40px] text-white bg-prgray-100 rounded-[46px] hover:bg-prgray-80 active:bg-prgray-90"
          onClick={_onClickSignup}
        >
          회원가입
        </button>
      </div>
    </div>
  );
};

export const TabletHeader = () => {
  return (
    <div
      className="fixed z-10 bg-white flex w-full h-[72px] justify-between items-center px-[32px] border-b border-prgray-20"
      style={{ zIndex: 9999 }}
    >
      <button onClick={_onClickLogo}>
        <img src={payroadLogo} className="w-[92px] h-[40px]" alt="" />
      </button>
      <div className="flex gap-x-[8px] sub4-sb">
        <button
          className="w-[78px] h-[40px] rounded-[46px] hover:bg-prgray-20 active:bg-prgray-40"
          onClick={_onClickFaq}
        >
          고객지원
        </button>
        <button
          className="w-[78px] h-[40px] rounded-[46px] hover:bg-prgray-20 active:bg-prgray-40"
          onClick={_onClickLogin}
        >
          로그인
        </button>
        <button
          className="w-[78px] h-[40px] text-white bg-prgray-100 rounded-[46px] hover:bg-prgray-80 active:bg-prgray-90"
          onClick={_onClickSignup}
        >
          회원가입
        </button>
      </div>
    </div>
  );
};

export const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div
      className="fixed z-10 flex flex-col bg-white w-full"
      style={{ zIndex: 9999 }}
    >
      <div className="w-full h-[72px] flex items-center justify-between bg-white px-[20px] border-b border-prgray-20">
        <button onClick={_onClickLogo}>
          <img src={payroadLogo} className="w-[92px] h-[40px]" alt="" />
        </button>
        <button onClick={() => setIsOpen(!isOpen)}>
          <img src={isOpen ? xMark : hamburger} alt="" />
        </button>
      </div>
      {isOpen && (
        <div className="h-screen flex flex-col items-center px-[21px] py-[48px]">
          <button
            className="w-[320px] h-[56px] bg-prblue rounded-[10px] mb-[10px] body2-sb text-white hover:bg-prblue-40 active:bg-prblue-60"
            onClick={_onClickLogin}
          >
            로그인
          </button>
          <button
            className="w-[320px] h-[56px] mb-[10px] bg-white border border-prgray-40 rounded-[10px] body2-sb hover:bg-prgray-20 active:bg-prgray-40"
            onClick={_onClickSignup}
          >
            회원가입
          </button>
          <button
            className="w-[320px] h-[56px] bg-white border border-prgray-40 rounded-[10px] body2-sb hover:bg-prgray-20 active:bg-prgray-40"
            onClick={_onClickFaq}
          >
            고객지원
          </button>
        </div>
      )}
    </div>
  );
};
