import headphone from "../../assets/images/headphone.svg";
import postalpine from "../../assets/images/postalpine-logo.svg";
import { PAYROAD_PRIVACY_LINK, PAYROAD_TERMS_LINK } from "../../statics/links";

const _onClickPrivacy = () => {
  window.open(PAYROAD_PRIVACY_LINK);
};
const _onClickTerms = () => {
  window.open(PAYROAD_TERMS_LINK);
};

export const FooterDesktop = () => {
  return (
    <div className="bg-prgray-20 w-full h-[424px] flex flex-col items-center justify-center py-[120px]">
      <div className="flex gap-x-[386px]">
        <div className="flex flex-col">
          <div className="flex mb-[24px]">
            <img src={postalpine} className="mr-[60px]" alt="" />
            <button className="sub4-eb mr-3" onClick={_onClickPrivacy}>
              개인정보처리방침
            </button>
            <button className="sub4-eb" onClick={_onClickTerms}>
              서비스이용약관
            </button>
          </div>
          <div className="sub4-eb mb-2">주식회사 포스트알파인</div>
          <div className="flex caption-r mb-2">
            <div>사업자등록번호 : 518-86-01993</div>
            <div className="border-l border-prgray-40 mx-2" />
            <div>대표 : 김지현</div>
          </div>
          <div className="flex caption-r mb-[46px]">
            <div>
              주소 : 서울특별시 마포구 공덕동 마포대로 122,
              11층(신용보증기금빌딩)
            </div>
            <div className="border-l border-prgray-40 mx-2" />
            <div>대표 전화 : 02-2088-4661</div>
          </div>
          <div className="caption-r">
            © Postalpine Inc. All rights reserved.
          </div>
        </div>

        <div className="w-[357px] h-[184px] bg-prgray-10 rounded-[20px] flex flex-col items-center justify-center">
          <div className="flex items-center gap-x-[12px] mb-[9px]">
            <img src={headphone} alt="" />
            <div className="sub1-eb">고객센터 운영 시간</div>
          </div>
          <div className="body1-r">10:00 ~ 19:00 (주말, 공휴일 제외)</div>
        </div>
      </div>
    </div>
  );
};

export const FooterTablet = () => {
  return (
    <div className="bg-prgray-20 w-full h-[708px] flex flex-col items-center justify-center py-[120px]">
      <div className="flex flex-col items-center">
        <div className="flex flex-col mb-[100px]">
          <div className="flex mb-[24px]">
            <img src={postalpine} className="mr-[60px]" alt="" />
            <button className="sub4-eb mr-3" onClick={_onClickPrivacy}>
              개인정보처리방침
            </button>
            <button className="sub4-eb" onClick={_onClickTerms}>
              서비스이용약관
            </button>
          </div>
          <div className="sub4-eb mb-2">주식회사 포스트알파인</div>
          <div className="flex caption-r mb-2">
            <div>사업자등록번호 : 518-86-01993</div>
            <div className="border-l border-prgray-40 mx-2" />
            <div>대표 : 김지현</div>
          </div>
          <div className="flex caption-r mb-[46px]">
            <div>
              주소 : 서울특별시 마포구 공덕동 마포대로 122,
              11층(신용보증기금빌딩)
            </div>
            <div className="border-l border-prgray-40 mx-2" />
            <div>대표 전화 : 02-2088-4661</div>
          </div>
          <div className="caption-r">
            © Postalpine Inc. All rights reserved.
          </div>
        </div>

        <div className="w-[457px] h-[184px] bg-prgray-10 rounded-[20px] flex flex-col items-center justify-center">
          <div className="flex items-center gap-x-[12px] mb-[9px]">
            <img src={headphone} alt="" />
            <div className="sub1-eb">고객센터 운영 시간</div>
          </div>
          <div className="body1-r">10:00 ~ 19:00 (주말, 공휴일 제외)</div>
        </div>
      </div>
    </div>
  );
};

export const FooterMobile = () => {
  return (
    <div className="bg-prgray-20 w-full h-[508px] flex flex-col items-center justify-center py-[60px]">
      <div className="flex flex-col items-center">
        <div className="flex flex-col mb-[48px]">
          <img src={postalpine} className="w-[130px] mb-[14px]" alt="" />
          <div className="flex mb-[12px]">
            <button
              className="caption-sb text-prgray-90 mr-3"
              onClick={_onClickPrivacy}
            >
              개인정보처리방침
            </button>
            <button
              className="caption-sb text-prgray-90"
              onClick={_onClickTerms}
            >
              서비스이용약관
            </button>
          </div>
          <div className="flex flex-col gap-y-[8px] mb-[46px]">
            <div className="caption-sb text-prgray-90">
              주식회사 포스트알파인
            </div>
            <div className="flex caption-r">
              <div>사업자등록번호 : 518-86-01993</div>
              <div className="border-l border-prgray-40 mx-2" />
              <div>대표 : 김지현</div>
            </div>
            <div className="caption-r">
              주소 : 서울특별시 마포구 공덕동 마포대로 122,
              11층(신용보증기금빌딩)
            </div>
            <div className="caption-r">대표 전화 : 02-2088-4661</div>
          </div>
          <div className="caption-r">
            © Postalpine Inc. All rights reserved.
          </div>
        </div>

        <div className="w-[314px] h-[110px] bg-prgray-10 rounded-[20px] flex flex-col items-center justify-center">
          <div className="flex items-center gap-x-[4px] mb-[9px]">
            <img src={headphone} className="w-5 h-5" alt="" />
            <div className="sub4-sb">고객센터 운영 시간</div>
          </div>
          <div className="caption-r">10:00 ~ 19:00 (주말, 공휴일 제외)</div>
        </div>
      </div>
    </div>
  );
};
