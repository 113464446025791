import fintech from "../../assets/images/fintech-logo.svg";
import kisa from "../../assets/images/kisa-logo.svg";
import klim from "../../assets/images/klim-logo.svg";
import nice from "../../assets/images/nice-logo.svg";

export const PartnerDesktop = () => {
  return (
    <div className="w-full h-[743px] flex flex-col items-center py-[180px]">
      <div className="h1-eb text-prblue mb-[74px]">
        페이로드와 함께 하는 협력사
      </div>
      <div className="grid grid-cols-2 gap-[24px]">
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={fintech} alt="" />
        </div>
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={nice} alt="" />
        </div>
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={kisa} alt="" />
        </div>
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={klim} alt="" />
        </div>
      </div>
    </div>
  );
};

export const PartnerTablet = () => {
  return (
    <div className="w-full h-[848px] flex flex-col items-center pb-[180px]">
      <div className="h1-eb text-prblue mb-[74px]">
        페이로드와 함께 하는 협력사
      </div>
      <div className="flex flex-col gap-y-[24px]">
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={fintech} alt="" />
        </div>
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={nice} alt="" />
        </div>
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={kisa} alt="" />
        </div>
        <div className="w-[588px] h-[120px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={klim} alt="" />
        </div>
      </div>
    </div>
  );
};

export const PartnerMobile = () => {
  return (
    <div className="w-full h-[369px] flex flex-col items-center pb-[180px]">
      <div className="h3-eb text-prblue mb-[48px]">
        페이로드와 함께 하는 협력사
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <div className="w-[320px] h-[45px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={fintech} className="w-[86.65px]" alt="" />
        </div>
        <div className="w-[320px] h-[45px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={nice} className="w-[92.48px]" alt="" />
        </div>
        <div className="w-[320px] h-[45px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={kisa} className="w-[99.9px]" alt="" />
        </div>
        <div className="w-[320px] h-[45px] border border-prgray-40 rounded-[10px] flex items-center justify-center">
          <img src={klim} className="w-[108.65px]" alt="" />
        </div>
      </div>
    </div>
  );
};
