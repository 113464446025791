import basket from "../../assets/images/basket.svg";
import blurBgDesktop from "../../assets/images/blur-bg-desktop.svg";
import blurBgMobile from "../../assets/images/blur-bg-mobile.svg";
import blurBgTablet from "../../assets/images/blur-bg-tablet.svg";
import buyerGrowth from "../../assets/images/buyer-growth.png";
import factory from "../../assets/images/factory.svg";
import seeSaw from "../../assets/images/see-saw.png";
import shop from "../../assets/images/shop.svg";
import stores from "../../assets/images/stores.svg";

const OnlineStoreBox = () => {
  return (
    <div className="border-2 border-prblue-10 rounded-[26px] w-[486px] h-[180px] flex flex-col items-center justify-center gap-y-[12px]">
      <img src={basket} alt="" />
      <div className="sub1-sb text-center">
        홈택스 연동이 가능하고 쿠팡 윙, 네이버 스마트스토어를
        <br />
        운영 하고 3개월 이상 매출이 있는 사장님
      </div>
    </div>
  );
};

const OfflineStoreBox = () => {
  return (
    <div className="border-2 border-prblue-10 rounded-[26px] w-[486px] h-[180px] flex flex-col items-center justify-center gap-y-[12px]">
      <img src={shop} alt="" />
      <div className="sub1-sb text-center">
        오프라인 가게를 운영하고, 여신금융협회
        <br />
        카드매출 연동이 가능한 사장님
      </div>
    </div>
  );
};

const CurrencyBox = () => {
  return (
    <div className="border-2 border-prblue-10 rounded-[26px] w-[486px] h-[210px] flex flex-col items-center justify-center gap-y-[12px]">
      <img src={factory} alt="" />
      <div className="sub1-sb text-center">
        대금 상환 늦어질 걱정은 No
        <br />
        번거로운 채권관리도 No
        <br />
        채권을 즉시 현금화 할 수 있습니다!
      </div>
    </div>
  );
};

export const TargetUserDesktop = () => {
  return (
    <div
      className="w-full h-[1758px] flex flex-col items-center py-[180px]"
      style={{ backgroundColor: "rgba(224, 224, 224, 0.15)" }}
    >
      <div
        className="absolute -z-10 w-full h-[1758px] translate-y-[-180px] blur-[150px]"
        style={{
          backgroundImage: `url(${blurBgDesktop})`,
          backgroundPosition: "center",
          backgroundSize: "auto 100%",
        }}
      />
      <div className="h1-eb text-prblue text-center mb-[69px]">
        페이로드의 후불 결제 서비스는 중•소상공인의
        <br />
        사업 성장 발판이 되어드립니다.
      </div>
      <div className="flex gap-x-[126px] mb-[69px]">
        <OnlineStoreBox />
        <img src={stores} alt="" />
      </div>
      <div className="flex gap-x-[126px] mb-[180px] items-center">
        <OfflineStoreBox />
        <img src={buyerGrowth} className="w-[384px] mx-[102px]" alt="" />
      </div>
      <div className="h1-eb text-prblue text-center mb-[74px]">
        중•소상공인과 거래하는 납품 기업 사장님들도
        <br />
        페이로드를 통해 거래하고 현금을 확보하세요!
      </div>
      <div className="flex items-center gap-x-[126px]">
        <img src={seeSaw} className="w-[588px]" alt="" />
        <CurrencyBox />
      </div>
    </div>
  );
};

export const TargetUserTablet = () => {
  return (
    <div
      className="w-full h-[2482px] flex flex-col items-center py-[180px]"
      style={{ backgroundColor: "rgba(224, 224, 224, 0.15)" }}
    >
      <div
        className="absolute -z-10 w-full h-[2482px] translate-y-[-180px] blur-[150px]"
        style={{
          backgroundImage: `url(${blurBgTablet})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
        }}
      />
      <div className="h1-eb text-prblue text-center mb-[69px]">
        페이로드의 후불 결제 서비스는 중•소상공인의
        <br />
        사업 성장 발판이 되어드립니다.
      </div>
      <div className="flex flex-col items-center gap-y-[48px] mb-[74px]">
        <OnlineStoreBox />
        <img src={stores} alt="" />
      </div>
      <div className="flex flex-col items-center gap-y-[48px] mb-[180px]">
        <OfflineStoreBox />
        <img src={buyerGrowth} className="w-[384px]" alt="" />
      </div>
      <div className="h1-eb text-prblue text-center mb-[74px]">
        중•소상공인과 거래하는 납품 기업 사장님들도
        <br />
        페이로드를 통해 거래하고 현금을 확보하세요!
      </div>
      <div className="flex flex-col items-center gap-y-[48px]">
        <img src={seeSaw} className="w-[509px] translate-x-[32px]" alt="" />
        <CurrencyBox />
      </div>
    </div>
  );
};

export const TargetUserMobile = () => {
  return (
    <div
      className="w-full h-[1541px] flex flex-col items-center py-[90px]"
      style={{ backgroundColor: "rgba(224, 224, 224, 0.15)" }}
    >
      <div
        className="absolute -z-10 w-full h-[1541px] translate-y-[-180px] blur-[150px]"
        style={{
          backgroundImage: `url(${blurBgMobile})`,
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
        }}
      />
      <div className="h3-eb text-prblue text-center mb-[48px]">
        페이로드의 후불 결제 서비스는
        <br />
        중•소상공인의 사업 성장 발판이
        <br />
        되어드립니다.
      </div>
      <div className="flex flex-col items-center gap-y-[24px] mb-[48px]">
        <div className="border-2 border-prblue-10 rounded-[26px] w-[292px] h-[132px] flex flex-col items-center justify-center gap-y-[12px]">
          <img src={basket} className="w-[24px]" alt="" />
          <div className="sub3-sb text-center">
            홈택스 연동이 가능하고<br/>쿠팡 윙, 네이버 스마트스토어를
            <br />
            운영 하고 3개월 이상 매출이 있는 사장님
          </div>
        </div>
        <img src={stores} className="w-[320px]" alt="" />
      </div>
      <div className="flex flex-col items-center gap-y-[24px] mb-[90px]">
        <div className="border-2 border-prblue-10 rounded-[26px] w-[292px] h-[132px] flex flex-col items-center justify-center gap-y-[12px]">
          <img src={shop} className="w-[24px]" alt="" />
          <div className="sub3-sb text-center">
            오프라인 가게를 운영하고, 여신금융협회
            <br />
            카드매출 연동이 가능한 사장님
          </div>
        </div>
        <img src={buyerGrowth} className="w-[209px]" alt="" />
      </div>
      <div className="h3-eb text-prblue text-center mb-[48px]">
        중•소상공인과 거래하는 납품 기업
        <br />
        사장님들도 페이로드를 통해
        <br />
        거래하고 현금을 확보하세요!
      </div>
      <div className="flex flex-col items-center gap-y-[24px] mb-[90px]">
        <div className="border-2 border-prblue-10 rounded-[26px] w-[292px] h-[156px] flex flex-col items-center justify-center gap-y-[12px]">
          <img src={factory} className="w-[24px]" alt="" />
          <div className="sub3-sb text-center">
            대금 상환 늦어질 걱정은 No
            <br />
            번거로운 채권관리도 No
            <br />
            채권을 즉시 현금화 할 수 있습니다!
          </div>
        </div>
        <img src={seeSaw} className="w-[277px] translate-x-[20px]" alt="" />
      </div>
    </div>
  );
};
