import { createContext } from "preact";
import { useMediaQuery } from "react-responsive";
import {
  MAX_MOBILE_SIZE,
  MAX_TABLET_SIZE,
  MIN_DESKTOP_SIZE,
  MIN_TABLET_SIZE,
} from "../../configs/responsive/responsive.config";

interface Props {
  children: preact.ComponentChildren;
}

const globalContext = createContext({
  isDesktop: false,
  isTablet: false,
  isMobile: false,
});

const GlobalContextProvider = ({ children }: Props) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${MIN_DESKTOP_SIZE}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${MIN_TABLET_SIZE}px) and (max-width: ${MAX_TABLET_SIZE}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${MAX_MOBILE_SIZE}px)`,
  });

  return (
    <globalContext.Provider value={{ isDesktop, isTablet, isMobile }}>
      {children}
    </globalContext.Provider>
  );
};

export { globalContext, GlobalContextProvider };
