import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "preact/hooks";
import { CtaBoxDesktop } from "../../../components/cta-box";
import { FooterDesktop } from "../../../components/footer";
import { DesktopHeader } from "../../../components/header";
import { HeroBannerDesktop } from "../../../components/hero-banner";
import { PainPointDesktop } from "../../../components/pain-point";
import { PartnerDesktop } from "../../../components/partner";
import { PayroadProcessDesktop } from "../../../components/payroad-process";
import { PayroadServiceDesktop } from "../../../components/payroad-service";
import { TargetMessageDesktop } from "../../../components/target-message";
import { TargetUserDesktop } from "../../../components/target-user";
import { UserCaseDesktop } from "../../../components/user-case";
import { FloatingButtonDesktop } from "src/components/floating-button";

const DesktopScreen = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<boolean>(false);
  const [scrollMid, setScrollMid] = useState<boolean>(false);
  const [scrollBot, setScrollBot] = useState<boolean>(false);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition + window.innerHeight > 1375) {
      setScrollTop(true);
    }
    if (scrollPosition + window.innerHeight > 5600) {
      setScrollMid(true);
    }
    if (scrollPosition + window.innerHeight > 9038) {
      setScrollBot(true);
    }
  }, [scrollPosition]);

  useEffect(() => {
    track("scroll_top_landing");
  }, [scrollTop]);
  useEffect(() => {
    track("scroll_middle_landing");
  }, [scrollMid]);
  useEffect(() => {
    track("scroll_bottom_landing");
  }, [scrollBot]);

  return (
    <>
      <DesktopHeader />
      <div className="flex flex-col items-center pt-[72px]">
        <HeroBannerDesktop />
        <TargetMessageDesktop />
        <PainPointDesktop />
        <PayroadServiceDesktop />
        <TargetUserDesktop />
        <PayroadProcessDesktop />
        <UserCaseDesktop />
        <PartnerDesktop />
        <CtaBoxDesktop />
        <FooterDesktop />
        <FloatingButtonDesktop />
      </div>
    </>
  );
};

export default DesktopScreen;
