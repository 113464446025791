import moneyGrowth from "../../assets/images/money-growth.png";
import targetMessage from "../../assets/images/target-message.svg";

export const TargetMessageDesktop = () => {
  return (
    <div className=" bg-prblue-5 w-full h-[467px] flex flex-col items-center justify-center">
      <div className="flex gap-x-[126px]">
        <img src={targetMessage} className="w-[384px]" alt="" />
        <img src={moneyGrowth} className="w-[486px]" alt="" />
      </div>
    </div>
  );
};

export const TargetMessageTablet = () => {
  return (
    <div className=" bg-prblue-5 w-full h-[732px] flex flex-col items-center justify-center">
      <div className="flex flex-col gap-y-[126px] items-center">
        <img src={targetMessage} className="w-[384px]" alt="" />
        <img src={moneyGrowth} className="w-[486px]" alt="" />
      </div>
    </div>
  );
};

export const TargetMessageMobile = () => {
  return (
    <div className=" bg-prblue-5 w-full h-[406px] flex flex-col items-center justify-center">
      <div className="flex flex-col gap-y-[48px] items-center">
        <img src={targetMessage} className="w-[292px]" alt="" />
        <img src={moneyGrowth} className="w-[272px]" alt="" />
      </div>
    </div>
  );
};
