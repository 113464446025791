import arrowCircleDown from "../../assets/images/arrow-circle-down.svg";
import bankAccountRequest from "../../assets/images/bank-account-request.svg";
import managerList from "../../assets/images/manager-list.svg";
import messageCircle from "../../assets/images/message-circle.svg";
import moneyPlant from "../../assets/images/money-plant.png";
import step1 from "../../assets/images/step1.svg";
import step2 from "../../assets/images/step2.svg";
import step3 from "../../assets/images/step3.svg";
import storeImage from "../../assets/images/store-image.png";
import storeInput1 from "../../assets/images/store-input1-mobile.svg";
import storeInput2 from "../../assets/images/store-input2-mobile.svg";
import storeList from "../../assets/images/store-list.svg";
import storeStep1 from "../../assets/images/store-step1-mobile.svg";
import storeStep2 from "../../assets/images/store-step2-mobile.svg";

const StoreListBox = () => {
  return (
    <div className="bg-prblue-10 rounded-[26px] px-[45px] py-[43px]">
      <div className="sub1-eb mb-[32px]">연동 가능한 스토어 리스트</div>
      <img src={storeList} className="w-full" alt="" />
    </div>
  );
};

const StoreIntegrationGuideBox = () => {
  return (
    <div className="w-[588px] bg-prblue-10 rounded-[26px] px-[55px] py-[50px]">
      <div className="sub1-eb mb-[32px]">스토어 연동 가이드</div>
      <div className="flex items-center mb-[9.37px]">
        <img src={messageCircle} className="mr-[6px]" alt="" />
        <span className="font-[600] text-[13px] leading-[19px] text-prblue">
          좌측 메뉴에서 판매자정보를 클릭하고 계정정보에 들어갑니다.
        </span>
      </div>
      <img src={storeImage} className="w-[477px]" alt="" />
      <div className="flex justify-center my-[19.6px]">
        <img src={arrowCircleDown} className="w-[26px]" alt="" />
      </div>
      <div className="flex items-center">
        <img src={messageCircle} className="mr-[6px]" alt="" />
        <span className="font-[600] text-[13px] leading-[19px] text-prblue">
          계정정보 화면 하단에 있는 추가계정 목록에서 계정 추가하기 버튼을
          클릭합니다.
        </span>
      </div>
    </div>
  );
};

const ExamineResultBox = () => {
  return (
    <div className="bg-prblue-10 rounded-[26px] px-[44px] py-[43px]">
      <div className="sub1-eb mb-[32px]">한도심사 결과 안내</div>
      <div className="flex justify-center gap-x-[28px]">
        <img src={moneyPlant} className="w-[220px]" alt="" />
        <div className="flex flex-col gap-y-[10px] w-full">
          <div className="flex bg-white h-[52px] items-center rounded-[10px] border border-prgray-60 px-[16px] justify-between">
            <div className="label">이용 가능 금액</div>
            <div className="filled-p">10,000,000 원</div>
          </div>
          <div className="flex bg-white h-[52px] items-center rounded-[10px] border border-prgray-60 px-[16px] justify-between">
            <div className="label">후불 결제 가능 기간</div>
            <div className="filled-p">60 일</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step1 = () => {
  return (
    <div className="w-[384px] h-[300px] bg-prviolet-10 rounded-[26px] flex flex-col items-center pt-[32px] px-[23px]">
      <div className="h4-eb text-prviolet mb-[24px]">거래 금액을 입력하고</div>
      <img src={step1} alt="" />
    </div>
  );
};

const Step2 = () => {
  return (
    <div className="w-[384px] h-[300px] bg-prviolet-10 rounded-[26px] flex flex-col items-center pt-[32px] px-[23px]">
      <div className="h4-eb text-prviolet mb-[24px]">거래 정보를 입력 후</div>
      <img src={step2} alt="" />
    </div>
  );
};

const Step3 = () => {
  return (
    <div className="w-[384px] h-[300px] bg-prviolet-10 rounded-[26px] flex flex-col items-center pt-[32px] px-[23px]">
      <div className="h4-eb text-prviolet mb-[24px]">
        상환 기한 설정 및 수수료를 확인하세요!
      </div>
      <img src={step3} alt="" />
    </div>
  );
};

const CmsBox = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-prgray-100 body3-sb text-white px-5 py-3 rounded-[110px] mb-[16px]">
        자동이체 출금
      </div>
      <div className="text-center sub1-sb mb-[32px]">
        바쁜 사업 운영으로 연체가 발생하지 않도록
        <br />
        페이로드에서 상환일에 맞춰 자동 출금해 드립니다.
      </div>
      <img src={bankAccountRequest} alt="" />
    </div>
  );
};

const ManagerBox = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-prgray-100 body3-sb text-white px-5 py-3 rounded-[110px] mb-[16px]">
        담당자 관리
      </div>
      <div className="text-center sub1-sb mb-[32px]">
        담당자를 추가해보세요! 사장님이
        <br />
        계시지 않아도 후불 결제 거래가 가능합니다.
      </div>
      <img src={managerList} alt="" />
    </div>
  );
};

export const PayroadServiceDesktop = () => {
  return (
    <div className="w-full h-[2121px] flex flex-col items-center mb-[180px]">
      <div className="h1-eb text-prblue mb-[74px]">
        페이로드 서비스 이용 방법
      </div>
      <div className="bg-prgray-100 body3-sb text-white px-5 py-3 rounded-[110px] mb-[16px]">
        스토어 연동 및 한도심사
      </div>
      <div className="text-center sub1-sb text-prgray-80 mb-[32px]">
        운영 중인 스토어 계정을 최초 1회 연동하시면,
        <br />
        AI가 자동으로 한도를 심사하여 알려드립니다.
      </div>
      <div className="flex gap-x-[24px] mb-[74px]">
        <div className="flex flex-col w-[588px] gap-y-[24px]">
          <StoreListBox />
          <ExamineResultBox />
        </div>
        <StoreIntegrationGuideBox />
      </div>
      <div className="bg-prgray-100 body3-sb text-white px-5 py-3 rounded-[110px] mb-[16px]">
        후불 결제 이용
      </div>
      <div className="text-center sub1-sb text-prgray-80 mb-[32px]">
        거래처와 페이로드를 통해 거래하세요. 거래대금은 페이로드에서 거래처에
        <br />
        먼저 지급해드리며, 사장님은 원하는 날짜에 상환하시면 됩니다!
      </div>
      <div className="flex gap-x-[24px] mb-[74px]">
        <Step1 />
        <Step2 />
        <Step3 />
      </div>
      <div className="flex gap-x-[24px]">
        <CmsBox />
        <ManagerBox />
      </div>
    </div>
  );
};

export const PayroadServiceTablet = () => {
  return (
    <div className="w-full h-[4064px] flex flex-col items-center mb-[180px]">
      <div className="h1-eb text-prblue mb-[74px]">
        페이로드 서비스 이용 방법
      </div>
      <div className="bg-prgray-100 body3-sb text-white px-5 py-3 rounded-[110px] mb-[16px]">
        스토어 연동 및 한도심사
      </div>
      <div className="text-center sub1-sb text-prgray-80 mb-[32px]">
        운영 중인 스토어 계정을 최초 1회 연동하시면,
        <br />
        AI가 자동으로 한도를 심사하여 알려드립니다.
      </div>

      <div className="flex flex-col gap-y-[24px] mb-[74px]">
        <StoreListBox />
        <StoreIntegrationGuideBox />
        <ExamineResultBox />
      </div>

      <div className="bg-prgray-100 body3-sb text-white px-5 py-3 rounded-[110px] mb-[16px]">
        후불 결제 이용
      </div>
      <div className="text-center sub1-sb text-prgray-80 mb-[32px]">
        거래처와 페이로드를 통해 거래하세요. 거래대금은 페이로드에서 거래처에
        <br />
        먼저 지급해드리며, 사장님은 원하는 날짜에 상환하시면 됩니다!
      </div>

      <div className="flex flex-col gap-y-[24px] mb-[74px]">
        <Step1 />
        <Step2 />
        <Step3 />
      </div>

      <div className="flex flex-col gap-y-[24px]">
        <CmsBox />
        <ManagerBox />
      </div>
    </div>
  );
};

export const PayroadServiceMobile = () => {
  return (
    <div className="w-full h-[2739px] flex flex-col items-center">
      <div className="h3-eb text-prblue mb-[48px]">
        페이로드 서비스 이용 방법
      </div>
      <div className="bg-prgray-100 body3-sb text-white px-[16px] py-2 rounded-[110px] mb-[16px]">
        스토어 연동 및 한도심사
      </div>
      <div className="text-center sub3-sb text-prgray-80 mb-[24px]">
        운영 중인 스토어 계정을 최초 1회 연동하시면,
        <br />
        AI가 자동으로 한도를 심사하여 알려드립니다.
      </div>

      <div className="flex flex-col gap-y-[10px] mb-[48px] items-center">
        <div className="bg-prblue-10 rounded-[16px] p-[25px] w-[320px]">
          <div className="sub4-eb mb-[12px]">연동 가능한 스토어 리스트</div>
          <img src={storeList} className="w-full" alt="" />
        </div>

        <div className="w-[320px] bg-prblue-10 rounded-[16px] p-[25px]">
          <div className="sub4-eb mb-[12px]">스토어 연동 가이드</div>
          <img src={storeStep1} className="mb-[4.63px]" alt="" />
          <img src={storeImage} className="w-[237.59px]" alt="" />
          <div className="flex justify-center my-[9.76px] w-[237.59px]">
            <img src={arrowCircleDown} className="w-[13px]" alt="" />
          </div>
          <img src={storeStep2} className="w-[206px]" alt="" />
        </div>

        <div className="w-[320px] bg-prblue-10 rounded-[16px] p-5">
          <div className="sub4-eb mb-[14px]">한도심사 결과 안내</div>
          <div className="flex justify-center items-center gap-x-[28px]">
            <img src={moneyPlant} className="w-[120px]" alt="" />
            <div className="flex flex-col gap-y-[5px] w-full">
              <img src={storeInput1} alt="" />
              <img src={storeInput2} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-prgray-100 body3-sb text-white px-[16px] py-[8px] rounded-[110px] mb-[16px]">
        후불 결제 이용
      </div>
      <div className="text-center sub3-sb text-prgray-80 mb-[24px]">
        거래처와 페이로드를 통해 거래하세요.
        <br />
        거래대금은 페이로드에서 거래처에 먼저 지급해
        <br />
        드리며, 사장님은 원하는 날짜에 상환하시면 됩니다!
      </div>

      <div className="flex flex-col gap-y-[10px] mb-[48px] items-center">
        <div className="w-[320px] h-[211px] bg-prviolet-10 rounded-[16px] flex flex-col items-center py-[24px] px-[19.17px]">
          <div className="sub4-eb text-prviolet mb-[20px]">
            거래 금액을 입력하고
          </div>
          <img src={step1} alt="" />
        </div>
        <div className="w-[320px] h-[252px] bg-prviolet-10 rounded-[16px] flex flex-col items-center py-[24px] px-[19.17px]">
          <div className="sub4-eb text-prviolet mb-[20px]">
            거래 정보를 입력 후
          </div>
          <img src={step2} alt="" />
        </div>
        <div className="w-[320px] h-[256px] bg-prviolet-10 rounded-[16px] flex flex-col items-center py-[24px] px-[19.17px]">
          <div className="sub4-eb text-prviolet mb-[20px]">
            상환 기한 설정 및 수수료를 확인하세요!
          </div>
          <img src={step3} alt="" />
        </div>
      </div>

      <div className="flex flex-col gap-y-[24px]">
        <div className="flex flex-col items-center">
          <div className="bg-prgray-100 body3-sb text-white px-[16px] py-[8px] rounded-[110px] mb-[16px]">
            자동이체 출금
          </div>
          <div className="text-center sub3-sb mb-[24px]">
            바쁜 사업 운영으로 연체가 발생하지 않도록
            <br />
            페이로드에서 상환일에 맞춰 자동 출금해 드립니다.
          </div>
          <img src={bankAccountRequest} className="w-[320px]" alt="" />
        </div>
        <div className="flex flex-col items-center">
          <div className="bg-prgray-100 body3-sb text-white px-[16px] py-[8px] rounded-[110px] mb-[16px]">
            담당자 관리
          </div>
          <div className="text-center sub3-sb mb-[24px]">
            담당자를 추가해보세요! 사장님이
            <br />
            계시지 않아도 후불 결제 거래가 가능합니다.
          </div>
          <img src={managerList} className="w-[320px]" alt="" />
        </div>
      </div>
    </div>
  );
};
