import painPoint from "../../assets/images/pain-point.svg";

export const PainPointDesktop = () => {
  return (
    <div className="w-full h-[852px] flex flex-col items-center py-[180px]">
      <div className="font-[800] text-[32px] text-prblue leading-[48px] mb-2">
        페이로드의 후불 결제 솔루션이 필요한 순간
      </div>
      <div className="sub1-sb mb-[74px]">
        잘 팔 수 있는데... 당장 매입 대금이 부족해 고민이신가요?
      </div>
      <img src={painPoint} alt="" />
    </div>
  );
};

export const PainPointTablet = () => {
  return (
    <div className="w-full h-[754px] flex flex-col items-center py-[180px]">
      <div className="font-[800] text-[32px] text-prblue leading-[48px] mb-2">
        페이로드의 후불 결제 솔루션이 필요한 순간
      </div>
      <div className="sub1-sb mb-[74px]">
        잘 팔 수 있는데... 당장 매입 대금이 부족해 고민이신가요?
      </div>
      <img src={painPoint} className="w-[702px]" alt="" />
    </div>
  );
};

export const PainPointMobile = () => {
  return (
    <div className="w-full h-[455px] flex flex-col items-center py-[90px]">
      <div className="h3-eb text-prblue mb-2 text-center">
        페이로드의 후불 결제 솔루션이
        <br />
        필요한 순간
      </div>
      <div className="sub3-sb mb-[48px] text-center">
        잘 팔 수 있는데... 당장 매입 대금이
        <br />
        부족해 고민이신가요?
      </div>
      <img src={painPoint} className="w-[320px]" alt="" />
    </div>
  );
};
