import { Desktop, Mobile, Tablet } from "src/components/responsive";
import DesktopScreen from "./desktop";
import MobileScreen from "./mobile";
import TabletScreen from "./tablet";

export const Main = () => {
  return (
    <>
      <Desktop>
        <DesktopScreen />
      </Desktop>

      <Tablet>
        <TabletScreen />
      </Tablet>

      <Mobile>
        <MobileScreen />
      </Mobile>
    </>
  );
};
