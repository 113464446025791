export const faqData = {
  서비스: [
    {
      question: "페이로드는 어떤 서비스인가요?",
      answer: `상품을 매입하시는 경우 페이로드에서 거래기업에 대금을 먼저 지급해드리고, 원하는 기일까지 페이로드에 대금을 상환하는 B2B 후불결제 서비스입니다.`,
    },
    {
      question: "페이로드는 대출인가요?",
      answer:
        "대출이 아닙니다. 거래에서 발생하는 매출채권을 페이로드에게 양도하여 이용하는 것으로 대출과 무관하며 신용등급에 영향을 미치지 않습니다.",
    },
    {
      question: "모바일에서는 이용 못하나요?",
      answer:
        "현재 페이로드 서비스는 PC 웹 브라우저를 통해 이용가능합니다. 모바일웹 또는 APP을 통한 서비스는 준비중입니다.",
    },
    {
      question: "하나의 계정으로 여러 사업장 운영이 가능한가요?",
      answer:
        "페이로드는 하나의 계정으로 하나의 사업장 운영만 가능합니다. 다른 사업장을 등록하고 싶으신 경우 새로 회원가입 및 사업자 등록을 진행해주셔야합니다.",
    },
  ],
  회원가입: [
    {
      question: "회원가입에 대한 조건이 따로 있나요?",
      answer:
        "회원가입은 사업자등록증상의 대표자만 가입 가능합니다. 후불 결제 진행을 담당하는 직원이 있을 경우 대표의 가입 후 담당자 추가 기능을 통해 해당 직원을 등록하여 후불 결제 이용이 가능합니다.",
    },
    {
      question: "회원가입 시 따로 제출해야 할 서류가 있나요?",
      answer:
        "네. 개인 사업자와 법인 사업자에 따라 제출해야하는 서류의 종류가 달라집니다.\n\n■ 개인사업자 : 사업자등록증\n■ 법인사업자 : 사업자등록증, 법인 등기부등본 사본(최근 3개월내 발급 분), 법인명의 통장 사본(법인명, 은행명, 계좌번호 식별), 주주명부, 대표자 신분증 사본, 부가세과세표준증명원, 국세납세증명서, 지방세납세증명서, 자동이체출금동의서\n\n법인의 경우 해당 서류를 documents@payroad.co 로 보내주시면 됩니다.",
    },
    {
      question: "회원정보를 변경할 수 있나요?",
      answer:
        "회원 정보 변경이 필요한 경우 고객센터로 문의 주시면 도움드리겠습니다.",
    },
    {
      question: "본인인증이 정상적으로 진행되지 않습니다.",
      answer:
        "본인인증 실패 사유는 몇 가지가 있을 수 있습니다.\n\n1. 이름/생년월일/통신사를 잘못 입력한 경우\n입력한 정보가 통신사에 저장된 정보와 다를 경우(이름은 띄어쓰기) 본인 인증에 실패할 수 있습니다.\n\n2. 휴대폰이 일시정지 또는 요금 미납인 경우\n요금 미납으로 인해 수발신이 전체 또는 일부만 정지 상태일 경우 인증에 실패할 수 있습니다.\n\n3. 휴대폰정보가 통신사에 등록되지 않은 경우\n안드로이드 휴대폰의 경우 '설정 → 일반 → 휴대폰정보' 메뉴에서 '내 휴대폰 번호'란에 본인 번호가 확인 되어야 합니다. 만일 본인의 번호가 확인되지 않는 경우 통신사로 정보를 업데이트 요청하면 인증 가능합니다.\n\n4. 투 넘버 사용 중인 경우\n인증하려는 전화번호가 메인 번호가 아닌 추가한 번호인 경우 인증이 어려울 수 있습니다. 메인 번호로 재 인증 시도 해주시고, 그래도 인증이 안되는 경우 이용하시는 통신사를 통해 문의 해주시기 바랍니다.\n\n5. 본인인증 서비스 장애\n본인인증을 진행하는 서비스측에 일시적인 오류로 인증이 되지 않을 수 있습니다. 시간을 두고 다시 시도해주시기 바랍니다.\n\n지속적으로 본인인증 절차에 문제가 있는 경우 페이로드 고객센터를 통해 문의 바랍니다.",
    },
    {
      question: "사업자등록증이 정상적으로 업로드 되지 않습니다.",
      answer:
        "1. 사업자등록증의 파일 형식이 잘못된 경우\nPNG, JPG, PDF 형식만 업로드 가능합니다. 또한, 사업자등록증은 1장으로만 이루어져 있어야 합니다. 형식을 벗어난 경우 수정 후에 다시 시도해 주세요.\n\n2. 사업자등록증 용량 초과\n최대 5MB의 파일만 업로드가 가능합니다. 용량을 초과하신 경우 오류가 발생할 수 있습니다.\n\n3. 대표자명이 일치하지 않는 경우\n본인인증에 사용된 성함과 사업자등록증에 기재된 대표자명은 반드시 일치해야합니다. 그렇지 않을 경우 가입 진행이 어렵습니다.",
    },
  ],
  "스토어 연동": [
    {
      question: "운영하는 스토어는 전부 연동 가능한가요?",
      answer:
        "페이로드는 현재 쿠팡 윙, 네이버 스마트스토어, 여신금융협회만 연동 가능합니다. 이 외의 스토어 연동은 현재 준비중입니다.",
    },
    {
      question: "페이로드를 왜 담당자로 추가해야하나요?",
      answer:
        "페이로드를 담당자로 등록해주셔야 자동으로 한도심사가 가능하며 이 후에도 주기적으로 고객님의 매출현황을 자동 측정하여 한도를 관리해드립니다. 한도심사외에 다른 목적으로 이용되지 않으며, 페이로드의 보안 시스템을 통해 계정을 안전하게 관리해드립니다.",
    },
    {
      question:
        "페이로드 계정을 스토어에 추가시 입력해야하는 정보들은 임의로 작성 가능한가요?",
      answer:
        "페이로드 화면에서 스토어 연동 → 원하는 스토어 리스트 클릭 후 가이드를 확인하시면 입력해야하는 정보들을 안내해드리고 있습니다. 반드시 페이로드가 지정해드린 정보를 입력해주셔야 정상적으로 연동이 가능하니, 가이드 확인 부탁드립니다.",
    },
    {
      question: "인증번호를 어떻게 확인 하나요?",
      answer:
        "운영 중인 스토어의 관리자 화면에서 인증하기 버튼을 누르신 후 페이로드 화면으로 돌아와 6자리 인증번호를 확인 후 스토어화면에 입력하시면 됩니다. 만약 인증번호 확인이 되지 않을 경우 고객센터로 문의 주시면 빠르게 해결해 드립니다.",
    },
  ],
  한도심사: [
    {
      question: "한도 산정하는 기준이 어떻게 되나요?",
      answer:
        "한도는 페이로드 이용횟수, 연동해주신 계정 데이터 등 100여가지 데이터를 활용하여 산정하고 있으며, 주기적으로 업데이트 됩니다.",
    },
    {
      question: "한도 0원이면 어떻게 하나요?",
      answer:
        "운영 중인 스토어의 상태에 따라 한도 산정이 되지 않을 수 있습니다.\n\n1. 스토어에 등록은 되어 있으나 상품 등록이 되어있지 않은 경우\n2. 스토어 등록 및 상품등록은 되어 있지만 매출이 발생하지 않은 경우\n3. 최근 3개월 내 매출이 저조하거나 없는 경우",
    },
    {
      question: "한도는 한번 산정 하고 나면 변동은 따로 없나요?",
      answer:
        "한도는 언제든지 변동이 가능합니다. 연동된 스토어를 통해 페이로드의 AI기술로 매출을 자동으로 트래킹하고 있습니다. 일주일에 1번씩 한도가 업데이트 되며, 매출 여부에 따라 한도 상승 또는 하락할 수 있습니다.",
    },
    {
      question: "한도는 언제 어떻게 복구되나요?",
      answer:
        "이용 중인 후불 결제 상환이 완료 되면 한도는 영업일 기준 1~2일 안에 복구 됩니다. 단, 연체 이력이 존재하는 경우 상환이 완료되어도 한도가 복구되지 않을 수 있습니다.",
    },
    {
      question: "한도를 늘리려면 어떻게 해야하나요?",
      answer:
        "운영 중인 스토어의 꾸준한 매출향상 및 페이로드를 통한 거래횟수가 많아진다면 한도는 기존보다 증가할 수 있습니다.",
    },
  ],
  "후불 결제": [
    {
      question: "후불 결제 이용을 위한 신청 조건은 어떻게 되나요?",
      answer:
        "운영 중인 스토어가 연동이 되어 있어야 하며, 후불 결제를 이용하시려는 금액과 거래증빙서류상의 거래 금액이 일치해야합니다.",
    },
    {
      question: "후불 결제 신청에 필요한 서류가 존재하나요?",
      answer:
        "제출해야하는 서류는 없습니다. 거래처의 사업자등록번호와 명함을 가지고 신청하시면 됩니다.",
    },
    {
      question: "해외 기업과의 거래도 가능한가요?",
      answer:
        "사입 목적의 해외기업과의 거래는 국내 병행 수입업체를 통하는 경우에 한해 가능하며, 해외 기업과의 직접 거래는 현재 지원하지 않고 있습니다.",
    },
    {
      question: "후불 결제 신청 시 거절될 수 있나요?",
      answer:
        "고객님의 신청내역을 페이로드에서 1차 검토합니다. 내용에 이상이 있다면 반려처리 될 수 있습니다. 반려 사유는 안내해 드리며, 해당 거래는 자동 취소됩니다. 다시 이용하시고자 할 경우 내용을 보충하여 새로 신청하실 수 있습니다.",
    },
    {
      question: "신청이 완료되면 거래대금은 거래처에 바로 입금 되나요?",
      answer:
        "고객님의 신청내역을 페이로드 내부에서 검토 후 이상이 없다면 검토 완료 처리 및 거래 기업에게 거래 승인 요청됩니다. 거래기업에서 거래를 승인하시면 영업일 기준 최대 48시간 내에 거래대금이 지급됩니다.",
    },
    {
      question: "거래 승인 링크가 모바일에서 제대로 열리지 않습니다.",
      answer:
        "현재 모바일 서비스는 준비중인 상태로 이용에 불편을 드려 죄송합니다. 문자를 통해 받으신 거래 승인 링크는 이용하시는 메신저 또는 이메일로 링크를 복사 하며 전송하신 후 PC화면에서 해당 링크에 접속하여 진행하시는 것을 권장드립니다.",
    },
    {
      question: "후불 결제 진행 상황을 알 수 있나요?",
      answer:
        "후불 결제 신청 완료 시점부터 페이로드에서 문자, 이메일, 웹 알림을 통해 프로세스별 상황을 알려드립니다. 서비스내 거래 내역 메뉴에서 해당 거래 내역을 클릭하시면 상세한 진행 상황을 확인하실 수 있습니다.",
    },
  ],
  "상환 및 수수료": [
    {
      question: "상환 수수료는 어떻게 되나요?",
      answer:
        "수수료는 요청하신 후불결제 금액에 할인율과 이용기간을 적용하여 산출합니다. 할인율은 페이로드의 신용평가엔진에 따라 산정되며, 30일 기준 1.5% 의 요금이 발생합니다.",
    },
    {
      question: "연체시 수수료는 어떻게 되나요?",
      answer: "연체 이자는 연 기준 19.9%가 적용됩니다.",
    },
    {
      question: "상환은 어떻게 이루어지나요?",
      answer:
        "등록하신 자동이체 출금 계좌를 통해 상환일에 페이로드가 자동 출금합니다. 상환일에 정상 출금이 이루어지지 않을 경우 연체가 될 수 있으니, 통장에 잔고를 반드시 유지해주세요.",
    },
  ],
  "운영 관리": [
    {
      question: "자동이체등록은 왜 해야 하나요?",
      answer:
        "페이로드의 자동이체 출금 서비스는 바쁜 스토어 운영으로 상환일에 납부 시기를 놓쳐 미납으로 인한 연체가 발생하는 등의 불편을 해소해드리기 위한 고객 편의 서비스입니다.",
    },
    {
      question: "계좌 변경도 가능한가요?",
      answer: "예금주명이 대표자의 성함 또는 사업자명일 경우 변경 가능합니다.",
    },
  ],
};
