export const lineBreaker = (text: string) => {
  if (text) {
    const paragraphs = text.split("\n\n");
    return (
      <>
        {paragraphs.map((paragraph, index) =>
          index < paragraphs.length - 1 ? (
            <div key={index}>
              {paragraph.split("\n").map((line, index) => (
                <div key={index}>{line}</div>
              ))}
              <br />
            </div>
          ) : (
            <div key={index}>
              {paragraph.split("\n").map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          )
        )}
      </>
    );
  } else return "";
};
