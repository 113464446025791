import { track } from "@amplitude/analytics-browser";
import heroBannerImageMobile from "../../assets/images/hero-banner-mobile.png";
import heroBannerImage from "../../assets/images/hero-banner.png";
import { PAYROAD_LOGIN_LINK } from "../../statics/links";

const gtagReportConversion = () => {
  window.gtag("event", "conversion", {
    send_to: "AW-11145749137/BD56CO-WqaAYEJHF2sIp",
  });

  window.fbq("track", "InitiateSignup", {
    content_name: "payroad",
  });
};

const _onClickCta = () => {
  gtagReportConversion();
  track("click_cta_1");
  window.location.href = PAYROAD_LOGIN_LINK;
};

export const HeroBannerDesktop = () => {
  return (
    <div
      className="w-full h-[835px] flex flex-col items-center pt-[120px]"
      style={{
        backgroundImage: `url(${heroBannerImage})`,
        backgroundPosition: "center",
        backgroundSize: "auto 100%",
      }}
    >
      <div className="font-[800] text-[52px] text-prgray-100 leading-[64px]">
        사장님을 위한 초간편
      </div>
      <div className="font-[800] text-[52px] text-prblue leading-[64px] mb-[20px]">
        후불 결제 서비스 페이로드
      </div>
      <div className="h3-sb mb-[32px]">
        페이로드가 기업간 거래의 숨통을 트여드립니다.
      </div>
      <button
        className="body2-sb px-[36px] py-[16px] bg-prblue rounded-[50px] text-white hover:bg-prblue-40 active:bg-prblue-60"
        onClick={_onClickCta}
      >
        후불 결제 이용하기
      </button>
    </div>
  );
};

export const HeroBannerTablet = () => {
  return (
    <div
      className="w-full h-[858px] flex flex-col items-center pt-[120px]"
      style={{
        backgroundImage: `url(${heroBannerImage})`,
        backgroundPosition: "center",
        backgroundSize: "auto 100%",
      }}
    >
      <div className="font-[800] text-[52px] text-prgray-100 leading-[64px]">
        사장님을 위한 초간편
      </div>
      <div className="font-[800] text-[52px] text-prblue leading-[64px] mb-[20px]">
        후불 결제 서비스 페이로드
      </div>
      <div className="h3-sb mb-[32px]">
        페이로드가 기업간 거래의 숨통을 트여드립니다.
      </div>
      <button
        className="body2-sb px-[36px] py-[16px] bg-prblue rounded-[50px] text-white hover:bg-prblue-40 active:bg-prblue-60"
        onClick={_onClickCta}
      >
        후불 결제 이용하기
      </button>
    </div>
  );
};

export const HeroBannerMobile = () => {
  return (
    <div
      className="w-full h-[429px] flex flex-col items-center pt-[60px]"
      style={{
        backgroundImage: `url(${heroBannerImageMobile})`,
        backgroundPosition: "center",
        backgroundSize: "auto 100%",
      }}
    >
      <div className="h2-eb">사장님을 위한 초간편</div>
      <div className="h2-eb text-prblue mb-[20px]">
        후불 결제 서비스 페이로드
      </div>
      <div className="h5-sb mb-[32px] text-prgray-80">
        페이로드가 기업간 거래의 숨통을 트여드립니다.
      </div>
      <button
        className="body2-sb px-[24px] py-[12px] bg-prblue rounded-[50px] text-white hover:bg-prblue-40 active:bg-prblue-60"
        onClick={_onClickCta}
      >
        후불 결제 이용
      </button>
    </div>
  );
};
