import React, { useContext } from "react";
import { globalContext } from "../../contexts/global";

interface Props {
  children: preact.ComponentChildren;
}

export const Desktop = ({ children }: Props) => {
  const { isDesktop } = useContext(globalContext);
  return <React.Fragment>{isDesktop && children}</React.Fragment>;
};

export const Tablet = ({ children }: Props) => {
  const { isTablet } = useContext(globalContext);
  return <React.Fragment>{isTablet && children}</React.Fragment>;
};

export const Mobile = ({ children }: Props) => {
  const { isMobile } = useContext(globalContext);
  return <React.Fragment>{isMobile && children}</React.Fragment>;
};
