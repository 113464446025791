import { init, track } from "@amplitude/analytics-browser";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import channel from "./configs/channel";
import { CHANNEL_IO_CONFIG } from "./configs/channel/channelIO.config.js";
import { GlobalContextProvider } from "./contexts/global";
import { FAQ } from "./pages/faq";
import { Main } from "./pages/main";
import { hotjar } from "react-hotjar";
import { HJID, HJSV } from "./configs/hotjar/hotjar.config";

function App() {
  useEffect(() => {
    channel.boot(CHANNEL_IO_CONFIG);
  }, []);

  useEffect(() => {
    init(process.env.REACT_APP_AMPLITUDE_API_KEY!!);
    setTimeout(() => track("enter_landing"), 500);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      hotjar.initialize(HJID, HJSV);
    }
  }, []);

  return (
    <GlobalContextProvider>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
    </GlobalContextProvider>
  );
}

export default App;
