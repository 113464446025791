import { track } from "@amplitude/analytics-browser";
import coinTarget from "../../assets/images/coin-target.png";
import { PAYROAD_LOGIN_LINK } from "../../statics/links";

const gtagReportConversion = () => {
  window.gtag("event", "conversion", {
    send_to: "AW-11145749137/BD56CO-WqaAYEJHF2sIp",
  });

  window.fbq("track", "InitiateSignup", {
    content_name: "payroad",
  });
};

const _onClickCta = () => {
  gtagReportConversion();
  track("click_cta_2");
  window.location.href = PAYROAD_LOGIN_LINK;
};

export const CtaBoxDesktop = () => {
  return (
    <div className="bg-prblue-40 w-full h-[453px] flex flex-col items-center justify-center py-[180px]">
      <div className="flex items-center gap-x-[124px]">
        <div className="flex flex-col">
          <div className="h1-eb text-white mb-[32px]">
            사업 성장을 위한 자금 마련,
            <br />
            페이로드와 함께 만들어보세요
          </div>
          <button
            className="w-[180px] h-[56px] bg-prgray-100 body2-sb text-white rounded-[50px] hover:bg-prgray-80 active:bg-prgray-90"
            onClick={_onClickCta}
          >
            후불 결제 이용하기
          </button>
        </div>
        <img src={coinTarget} className="w-[572px]" alt="" />
      </div>
    </div>
  );
};

export const CtaBoxTablet = () => {
  return (
    <div className="bg-prblue-40 w-full h-[801px] flex flex-col items-center justify-center py-[180px]">
      <div className="flex flex-col items-center gap-x-[124px]">
        <div className="flex flex-col items-center mb-[124px]">
          <div className="h1-eb text-white mb-[32px] text-center">
            사업 성장을 위한 자금 마련,
            <br />
            페이로드와 함께 만들어보세요
          </div>
          <button
            className="w-[180px] h-[56px] bg-prgray-100 body2-sb text-white rounded-[50px] hover:bg-prgray-80 active:bg-prgray-90"
            onClick={_onClickCta}
          >
            후불 결제 이용하기
          </button>
        </div>
        <img src={coinTarget} className="w-[572px]" alt="" />
      </div>
    </div>
  );
};

export const CtaBoxMobile = () => {
  return (
    <div className="bg-prblue-40 w-full h-[539px] flex flex-col items-center justify-center py-[90px]">
      <div className="flex flex-col items-center gap-x-[124px]">
        <div className="flex flex-col items-center mb-[48px]">
          <div className="h3-eb text-white mb-[32px] text-center">
            사업 성장을 위한 자금 마련,
            <br />
            페이로드와 함께 만들어보세요
          </div>
          <button
            className="w-[180px] h-[56px] bg-prgray-100 body2-sb text-white rounded-[50px] hover:bg-prgray-80 active:bg-prgray-90"
            onClick={_onClickCta}
          >
            후불 결제 이용하기
          </button>
        </div>
        <img src={coinTarget} className="w-[360px]" alt="" />
      </div>
    </div>
  );
};
