import { track } from "@amplitude/analytics-browser";
import { useEffect, useState } from "preact/hooks";
import { CtaBoxMobile } from "../../../components/cta-box";
import { FooterMobile } from "../../../components/footer";
import { MobileHeader } from "../../../components/header";
import { HeroBannerMobile } from "../../../components/hero-banner";
import { PainPointMobile } from "../../../components/pain-point";
import { PartnerMobile } from "../../../components/partner";
import { PayroadProcessMobile } from "../../../components/payroad-process";
import { PayroadServiceMobile } from "../../../components/payroad-service";
import { TargetMessageMobile } from "../../../components/target-message";
import { TargetUserMobile } from "../../../components/target-user";
import { UserCaseMobile } from "../../../components/user-case";
import { FloatingButtonMobile } from "src/components/floating-button";

const MobileScreen = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<boolean>(false);
  const [scrollMid, setScrollMid] = useState<boolean>(false);
  const [scrollBot, setScrollBot] = useState<boolean>(false);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition + window.innerHeight > 910) {
      setScrollTop(true);
    }
    if (scrollPosition + window.innerHeight > 5050) {
      setScrollMid(true);
    }
    if (scrollPosition + window.innerHeight > 8988) {
      setScrollBot(true);
    }
  }, [scrollPosition]);

  useEffect(() => {
    track("scroll_top_landing");
  }, [scrollTop]);
  useEffect(() => {
    track("scroll_middle_landing");
  }, [scrollMid]);
  useEffect(() => {
    track("scroll_bottom_landing");
  }, [scrollBot]);

  return (
    <>
      <MobileHeader />
      <div className="flex flex-col items-center pt-[72px]">
        <HeroBannerMobile />
        <TargetMessageMobile />
        <PainPointMobile />
        <PayroadServiceMobile />
        <TargetUserMobile />
        <PayroadProcessMobile />
        <UserCaseMobile />
        <PartnerMobile />
        <CtaBoxMobile />
        <FooterMobile />
        <FloatingButtonMobile />
      </div>
    </>
  );
};

export default MobileScreen;
