import { useEffect, useState } from "preact/hooks";
import chevronDown from "src/assets/images/chevron-down.svg";
import { MIN_TABLET_SIZE } from "src/configs/responsive/responsive.config";
import { faqData } from "src/statics/texts";
import { lineBreaker } from "src/utils";

type Focus =
  | "서비스"
  | "스토어 연동"
  | "한도심사"
  | "후불 결제"
  | "상환 및 수수료"
  | "회원가입"
  | "운영 관리";

interface ButtonProps {
  text: Focus;
}

export const FaqDesktop = () => {
  const [focus, setFocus] = useState<Focus>("서비스");

  const Button = (props: ButtonProps) => {
    return (
      <button
        className={`px-[16px] py-[12px] rounded-[100px] body2-sb ${
          focus === props.text
            ? "bg-prgray-100 text-white"
            : "text-prgray-90 hover:bg-prgray-20 active:bg-prgray-40"
        }`}
        onClick={() => setFocus(props.text)}
      >
        {props.text}
      </button>
    );
  };

  return (
    <div className="w-full flex flex-col items-center px-[32px]">
      <div className="h1-eb mt-[120px] mb-[60px]">자주 묻는 질문</div>
      <div className="flex gap-x-[12px] mb-[32px] justify-center">
        <Button text="서비스" />
        <Button text="스토어 연동" />
        <Button text="한도심사" />
        <Button text="후불 결제" />
        <Button text="상환 및 수수료" />
        <Button text="회원가입" />
        <Button text="운영 관리" />
      </div>
      {<FaqListDesktop data={faqData[focus]} />}
    </div>
  );
};

export const FaqTablet = () => {
  const [focus, setFocus] = useState<Focus>("서비스");

  const Button = (props: ButtonProps) => {
    return (
      <button
        className={`px-[16px] py-[12px] rounded-[100px] body2-sb whitespace-nowrap ${
          focus === props.text
            ? "bg-prgray-100 text-white"
            : "text-prgray-90 hover:bg-prgray-20 active:bg-prgray-40"
        }`}
        onClick={() => setFocus(props.text)}
      >
        {props.text}
      </button>
    );
  };

  return (
    <div className="w-full flex flex-col items-center px-[32px]">
      <div className="h1-eb mt-[120px] mb-[60px]">자주 묻는 질문</div>
      <div
        className={`flex gap-x-[12px] mb-[32px] min-w-[${
          MIN_TABLET_SIZE + 32
        }px] justify-center`}
      >
        <Button text="서비스" />
        <Button text="스토어 연동" />
        <Button text="한도심사" />
        <Button text="후불 결제" />
        <Button text="상환 및 수수료" />
        <Button text="회원가입" />
        <Button text="운영 관리" />
      </div>
      {<FaqListTablet data={faqData[focus]} />}
    </div>
  );
};

export const FaqMobile = () => {
  const [focus, setFocus] = useState<Focus>("서비스");

  const Button = (props: ButtonProps) => {
    return (
      <button
        className={`px-[12px] py-[4px] rounded-[100px] text-[14px] font-[600] leading-[24px] whitespace-nowrap ${
          focus === props.text
            ? "bg-prgray-100 text-white"
            : "text-prgray-90 active:bg-prgray-40"
        }`}
        onClick={() => setFocus(props.text)}
      >
        {props.text}
      </button>
    );
  };

  return (
    <div className="w-full flex flex-col items-center px-[32px]">
      <div className="h3-eb mt-[60px] mb-[32px]">자주 묻는 질문</div>
      <div className="max-w-full overflow-hidden">
        <div className="flex gap-x-[8px] mb-[20px] max-w-full overflow-scroll scrollbar-none">
          <Button text="서비스" />
          <Button text="스토어 연동" />
          <Button text="한도심사" />
          <Button text="후불 결제" />
          <Button text="상환 및 수수료" />
          <Button text="회원가입" />
          <Button text="운영 관리" />
        </div>
      </div>
      {<FaqListMobile data={faqData[focus]} />}
    </div>
  );
};

const FaqListDesktop = ({ data }: any) => {
  const [open, setOpen] = useState<number | null>();

  useEffect(() => {
    setOpen(null);
  }, [data]);

  const FaqItem = ({ datum, index }: any) => {
    const _onClick = () => {
      if (open === index) {
        setOpen(null);
      } else {
        setOpen(index);
      }
    };

    return (
      <div>
        <button
          className={`flex justify-between w-full ${
            open === index
              ? "px-4 pt-4 pb-3 rounded-t-[16px] bg-prgray-40 border border-prgray-40"
              : "p-4 rounded-[16px] border border-prgray-20 hover:bg-prgray-20 active:bg-prgray-40"
          }`}
          onClick={_onClick}
        >
          <span className="grow mr-[12px] text-left sub2-sb">
            {datum.question}
          </span>
          <img
            src={chevronDown}
            width={24}
            className={`${open === index ? "rotate-180" : ""}`}
            alt=""
          />
        </button>
        {open === index && (
          <button
            className="w-full text-start body2-r bg-prgray-40 pl-[16px] pr-[52px] pb-4 rounded-b-[14px]"
            onClick={_onClick}
          >
            {lineBreaker(datum.answer)}
          </button>
        )}
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-y-[12px] w-full max-w-[1060px] mb-[400px]">
      {data.map((datum: any, index: any) => (
        <FaqItem datum={datum} index={index} />
      ))}
    </div>
  );
};

const FaqListTablet = ({ data }: any) => {
  const [open, setOpen] = useState<number | null>();

  useEffect(() => {
    setOpen(null);
  }, [data]);

  const FaqItem = ({ datum, index }: any) => {
    const _onClick = () => {
      if (open === index) {
        setOpen(null);
      } else {
        setOpen(index);
      }
    };
    return (
      <div>
        <button
          className={`flex justify-between w-full ${
            open === index
              ? "px-4 pt-4 pb-3 rounded-t-[16px] bg-prgray-40 border border-prgray-40"
              : "p-4 rounded-[16px] border border-prgray-20 hover:bg-prgray-20 active:bg-prgray-40"
          }`}
          onClick={_onClick}
        >
          <span className="grow mr-[12px] text-left sub2-sb">
            {datum.question}
          </span>
          <img
            src={chevronDown}
            width={24}
            className={`${open === index ? "rotate-180" : ""}`}
            alt=""
          />
        </button>
        {open === index && (
          <button
            className="w-full text-start body2-r bg-prgray-40 pl-[16px] pr-[52px] pb-4 rounded-b-[14px]"
            onClick={_onClick}
          >
            {lineBreaker(datum.answer)}
          </button>
        )}
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-y-[12px] w-full max-w-[1060px] mb-[400px]">
      {data.map((datum: any, index: any) => (
        <FaqItem datum={datum} index={index} />
      ))}
    </div>
  );
};

const FaqListMobile = ({ data }: any) => {
  const [open, setOpen] = useState<number | null>();

  useEffect(() => {
    setOpen(null);
  }, [data]);

  const FaqItem = ({ datum, index }: any) => {
    const _onClick = () => {
      if (open === index) {
        setOpen(null);
      } else {
        setOpen(index);
      }
    };

    return (
      <div>
        <button
          className={`flex justify-between w-full ${
            open === index
              ? "px-4 pt-4 pb-3 rounded-t-[16px] bg-prgray-40 border border-prgray-40"
              : "p-4 rounded-[16px] border border-prgray-20 active:bg-prgray-40"
          }`}
          onClick={_onClick}
        >
          <span className="grow mr-[12px] text-left sub3-sb">
            {datum.question}
          </span>
          <img
            src={chevronDown}
            width={24}
            className={`${open === index ? "rotate-180" : ""}`}
            alt=""
          />
        </button>
        {open === index && (
          <div
            className="w-full text-left body3-r bg-prgray-40 pl-[16px] pr-[52px] pb-4 rounded-b-[14px]"
            onClick={_onClick}
          >
            {lineBreaker(datum.answer)}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-y-[12px] w-full max-w-[1060px] mb-[400px]">
      {data.map((datum: any, index: any) => (
        <FaqItem datum={datum} index={index} />
      ))}
    </div>
  );
};
