import human1 from "../../assets/images/human1.svg";
import human2 from "../../assets/images/human2.svg";
import human3 from "../../assets/images/human3.svg";
import desktopMessage1 from "../../assets/images/message-desktop-1.svg";
import desktopMessage2 from "../../assets/images/message-desktop-2.svg";
import desktopMessage3 from "../../assets/images/message-desktop-3.svg";
import mobileMessage1 from "../../assets/images/message-mobile-1.svg";
import mobileMessage2 from "../../assets/images/message-mobile-2.svg";
import mobileMessage3 from "../../assets/images/message-mobile-3.svg";
import tabletMessage1 from "../../assets/images/message-tablet-1.svg";
import tabletMessage2 from "../../assets/images/message-tablet-2.svg";
import tabletMessage3 from "../../assets/images/message-tablet-3.svg";

export const UserCaseDesktop = () => {
  return (
    <div className="w-full h-[1010px] flex flex-col items-center pt-[180px]">
      <div className="h1-eb text-prblue mb-[74px]">
        페이로드를 통해 사업 성장을 이룬 고객 사례
      </div>
      <div className="w-[1200px] flex flex-col gap-y-[84px] mb-[180px]">
        <div className="flex items-start justify-end gap-x-[24px]">
          <img src={desktopMessage1} alt="" />
          <img src={human1} alt="" />
        </div>
        <div className="flex items-start justify-start gap-x-[24px]">
          <img src={human2} alt="" />
          <img src={desktopMessage2} alt="" />
        </div>
        <div className="flex items-start justify-end gap-x-[24px]">
          <img src={desktopMessage3} alt="" />
          <img src={human3} alt="" />
        </div>
      </div>
    </div>
  );
};

export const UserCaseTablet = () => {
  return (
    <div className="w-full h-[1481px] flex flex-col items-center py-[180px]">
      <div className="h1-eb text-prblue mb-[74px]">
        페이로드를 통해 사업 성장을 이룬 고객 사례
      </div>
      <div className="w-full flex flex-col gap-y-[84px] mb-[180px] px-[40px]">
        <div className="flex flex-col items-center gap-y-[24px]">
          <img src={tabletMessage1} className="w-[486px]" alt="" />
          <img src={human1} className="w-[120px]" alt="" />
        </div>
        <div className="flex flex-col items-center gap-y-[24px]">
          <img src={tabletMessage2} className="w-[486px]" alt="" />
          <img src={human2} className="w-[120px]" alt="" />
        </div>
        <div className="flex flex-col items-center gap-y-[24px]">
          <img src={tabletMessage3} className="w-[486px]" alt="" />
          <img src={human3} className="w-[120px]" alt="" />
        </div>
      </div>
    </div>
  );
};

export const UserCaseMobile = () => {
  return (
    <div className="w-full h-[1153px] flex flex-col items-center py-[90px]">
      <div className="h3-eb text-prblue mb-[48px] text-center">
        페이로드를 통해 사업 성장을
        <br />
        이룬 고객 사례
      </div>
      <div className="w-full flex flex-col gap-y-[24px] mb-[180px] px-[40px]">
        <div className="flex flex-col items-center gap-y-[12px]">
          <img src={mobileMessage1} className="w-[320px]" alt="" />
          <img src={human1} className="w-[120px]" alt="" />
        </div>
        <div className="flex flex-col items-center gap-y-[12px]">
          <img src={mobileMessage2} className="w-[320px]" alt="" />
          <img src={human2} className="w-[120px]" alt="" />
        </div>
        <div className="flex flex-col items-center gap-y-[12px]">
          <img src={mobileMessage3} className="w-[320px]" alt="" />
          <img src={human3} className="w-[120px]" alt="" />
        </div>
      </div>
    </div>
  );
};
