import { FaqDesktop } from "src/components/faq";
import { FooterDesktop } from "src/components/footer";
import { DesktopHeader } from "src/components/header";

const DesktopScreen = () => {
  return (
    <>
      <DesktopHeader />
      <div className="flex flex-col items-center pt-[72px]">
        <FaqDesktop />
        <FooterDesktop />
      </div>
    </>
  );
};

export default DesktopScreen;
