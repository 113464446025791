import { FaqTablet } from "src/components/faq";
import { FooterTablet } from "src/components/footer";
import { TabletHeader } from "src/components/header";

const TabletScreen = () => {
  return (
    <>
      <TabletHeader />
      <div className="flex flex-col items-center pt-[72px]">
        <FaqTablet />
        <FooterTablet />
      </div>
    </>
  );
};

export default TabletScreen;
